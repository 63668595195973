import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { openFixturePopupEffect$, openFixturePopupFailEffect$, openFixturePopupLockFailEffect$, openFixturePopupSuccessEffect$ } from "./open-fixture";
import { saveFixturePopupEffect$, saveFixturePopupFailEffect$, saveFixturePopupSuccessEffect$ } from "./save-fixture";
import { FixturePopupFeatureState } from "./state";
import { unlockFixturePopupEffect$, unlockFixturePopupFailEffect$ } from "./unlock-fixture";
import { VoyageHttpService } from "../../fixture/services";
import { ExpenseDataService } from "../../fixture/services/expense-data.service";
import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { FixtureHttpService } from "../../fixture/services/fixture-http.service";
import { VoyageDataService } from "../../fixture/services/voyage-data.service";
import { FixtureDataInterop } from "../../fixture/state";
import { FixtureRowUpdatesService } from "../../fixtures/services/fixture-row-updates.service";
import { NotificationService } from "../../shared";

@Injectable()
export class FixturePopupEffects {
    openFixturePopupEffect$ = openFixturePopupEffect$(this.actions$, this.fixtureDataService, this.fixtureDataInterop, this.voyageDataService, this.expenseDataService);

    openFixturePopupSuccessEffect$ = openFixturePopupSuccessEffect$(this.actions$, this.fixtureHttpService);

    saveFixturePopupEffect$ = saveFixturePopupEffect$(
        this.actions$,
        this.store,
        this.fixtureHttpService,
        this.voyageHttpService,
        this.fixtureDataService,
        this.voyageDataService,
        this.fixtureRowUpdatesService
    );

    unlockFixturePopupEffect$ = unlockFixturePopupEffect$(this.actions$, this.store, this.fixtureHttpService, this.fixtureDataService, this.fixtureDataInterop);

    openFixturePopupFailEffect$ = openFixturePopupFailEffect$(this.actions$, this.notificationService);

    openFixturePopupLockFailEffect$ = openFixturePopupLockFailEffect$(this.actions$, this.notificationService);

    saveFixturePopupSuccessEffect$ = saveFixturePopupSuccessEffect$(this.actions$, this.fixtureDataService, this.fixtureDataInterop);

    saveFixturePopupFailEffect$ = saveFixturePopupFailEffect$(this.actions$, this.notificationService);

    unlockFixturePopupFailEffect$ = unlockFixturePopupFailEffect$(this.actions$, this.notificationService);

    constructor(
        private actions$: Actions,
        private store: Store<FixturePopupFeatureState>,
        private fixtureHttpService: FixtureHttpService,
        private voyageHttpService: VoyageHttpService,
        private fixtureDataService: FixtureDataService,
        private fixtureDataInterop: FixtureDataInterop,
        private voyageDataService: VoyageDataService,
        private expenseDataService: ExpenseDataService,
        private fixtureRowUpdatesService: FixtureRowUpdatesService,
        private notificationService: NotificationService
    ) {}
}
