import { Actions, createEffect, ofType } from "@ngrx/effects";
import { On, Store, createAction, on, props } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, tap, withLatestFrom } from "rxjs/operators";

import { selectUnlockOnClose } from "./selectors";
import { FixturePopupFeatureState, FixturePopupState, initialState } from "./state";
import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { FixtureHttpService } from "../../fixture/services/fixture-http.service";
import { FixtureDataInterop } from "../../fixture/state";
import { GENERIC_ERROR_MESSAGE, NotificationService } from "../../shared";

/* ACTIONS */
const UNLOCK_FIXTURE_POPUP_NAME = "[Fixture Popup] Unlock";

export const unlockFixturePopupAction = createAction(UNLOCK_FIXTURE_POPUP_NAME);
export const unlockFixturePopupSuccessAction = createAction(`${UNLOCK_FIXTURE_POPUP_NAME} Success`);
export const unlockFixturePopupFailAction = createAction(`${UNLOCK_FIXTURE_POPUP_NAME} Fail`, props<{ error: Error }>());

/* REDUCERS */
export const unlockFixturePopupReducer: On<FixturePopupState> = on(unlockFixturePopupAction, (state) => (state.unlockOnClose ? state : initialState));

export const unlockFixturePopupSuccessReducer: On<FixturePopupState> = on(unlockFixturePopupSuccessAction, () => initialState);

/* EFFECTS */
export const unlockFixturePopupEffect$ = (
    actions$: Actions,
    store: Store<FixturePopupFeatureState>,
    fixtureHttpService: FixtureHttpService,
    fixtureDataService: FixtureDataService,
    fixtureDataInterop: FixtureDataInterop
) =>
    createEffect(() =>
        actions$.pipe(
            ofType(unlockFixturePopupAction),
            withLatestFrom(fixtureDataService.currentFixture$, store.select(selectUnlockOnClose)),
            tap(() => {
                fixtureDataService.clearFixture();
                fixtureDataInterop.destroy();
            }),
            filter(([, , unlockOnClose]) => unlockOnClose),
            exhaustMap(([, fixture, _]) =>
                fixtureHttpService.unlock(fixture.fixtureId).pipe(
                    map(() => unlockFixturePopupSuccessAction()),
                    catchError((error) => of(unlockFixturePopupFailAction({ error })))
                )
            )
        )
    );

export const unlockFixturePopupFailEffect$ = (actions$: Actions, notificationService: NotificationService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(unlockFixturePopupFailAction),
                tap(({ error }) => console.error(error)),
                tap(() => notificationService.error("Failed to unlock fixture.", GENERIC_ERROR_MESSAGE))
            ),
        { dispatch: false }
    );
